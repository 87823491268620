var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"SimulationImportMass"}},[[(!_vm.isEdit)?_c('div',{staticClass:"simulator-step_header"},[_c('h3',{staticClass:"simulator-step_title"},[_vm._v(_vm._s(_vm.$t("add_the_new_simulation")))]),_c('p',{staticClass:"simulator-step_description"},[_vm._v(" Importer des opérations en masse: ")])]):_vm._e(),_c('div',{staticClass:"giga-fields fields-style"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"giga-input_field "},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("operation_type")))]),_c('div',{staticClass:"input-field_area"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.importOperations.isMorale.$model),expression:"$v.importOperations.isMorale.$model"}],staticClass:"small-input",class:_vm.$v.importOperations.isMorale.$anyDirty &&
                _vm.$v.importOperations.isMorale.$invalid
                  ? 'field-hasError'
                  : '',attrs:{"id":"operation-type","disabled":_vm.isVIEWER},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.importOperations.isMorale, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.fetchFiches]}},[_c('option',{key:"MORALE",domProps:{"value":true}},[_vm._v("Personne morale")]),_c('option',{key:"PHYSIQUE",domProps:{"value":false}},[_vm._v("Personne physique")])])])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"giga-input_field "},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("fiche")))]),_c('div',{staticClass:"input-field_area"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.importOperations.fiche.$model),expression:"$v.importOperations.fiche.$model"}],staticClass:"small-input",class:_vm.$v.importOperations.fiche.$anyDirty &&
                _vm.$v.importOperations.fiche.$invalid
                  ? 'field-hasError'
                  : '',attrs:{"id":"select-fiches","disabled":_vm.isVIEWER},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.importOperations.fiche, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.fetchMappings(true)}]}},_vm._l((_vm.fiches),function(fiche){return _c('option',{key:fiche.id,domProps:{"value":fiche.id}},[_vm._v(_vm._s(fiche.number))])}),0)])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"giga-input_field "},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("mapping")))]),_c('div',{staticClass:"input-field_area"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.importOperations.mapping.$model),expression:"$v.importOperations.mapping.$model"}],staticClass:"small-input",class:_vm.$v.importOperations.mapping.$anyDirty &&
                _vm.$v.importOperations.mapping.$invalid
                  ? 'field-hasError'
                  : '',attrs:{"id":"select-mappings","disabled":_vm.isVIEWER},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.importOperations.mapping, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.mappings),function(mapping){return _c('option',{key:mapping.id,domProps:{"value":mapping.id}},[_vm._v(_vm._s(mapping.name))])}),0)])])]),_c('div',{staticClass:"col-lg-6"},[(_vm.$v.importOperations.mapping.$model)?_c('div',{staticClass:"consultant-template"},[_c('img',{attrs:{"src":require("@/assets/images/paper-line.svg")}}),_c('span',[_vm._v("Télécharger le template consultant")]),_c('button',{on:{"click":function($event){return _vm.downloadMappingFile()}}},[_c('span',{staticClass:"material-symbols-outlined"},[_vm._v("download")])])]):_vm._e()]),_c('import-mass-documents',{staticClass:"import-doc-input",attrs:{"simulation":true,"files":_vm.files,"importInMassOperations":_vm.previewUpload,"isSimulationStepper":true},on:{"estimations-preview-updated":_vm.handleEstimationsPreviewUpdate}}),_c('div',{staticClass:"operations-list-div",attrs:{"hidden":!_vm.operations ||
            (_vm.operations && _vm.operations.length === 0) ||
            _vm.simulation.status === 'NEW'}},[_c('list-operations-in-mass',{on:{"operations-updated":_vm.handleOperationsUpdate}})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }