<template>
  <div id="SimulationOperations">
    <page-loader v-if="loading"></page-loader>

    <div class="simulator-step_header step-row">
      <div class="step-half_header">
        <template v-if="!isEdit">
          <h3 class="simulator-step_title">
            {{ $t("add_the_new_simulation") }}
          </h3>
          <p class="simulator-step_description">Remplir la formulaire:</p>
        </template>
      </div>
      <div class="step-half_header flex-end">
        <div class="giga-button">
          <button
            id="add-address"
            v-if="!isAnArtisan"
            @click.prevent="newTabAdresse"
            class="giga-blue_button no-icon"
            :disabled="isVIEWER"
          >
            Ajouter une adresse
          </button>
        </div>
      </div>
    </div>

    <div v-if="!loading" class="accordion" role="tablist">
      <div
        class="giga-accordion_row"
        ref="tabsAddress"
        v-for="(address, a) in tabsAddress"
        :key="'dyn-tab-' + a"
        :set="(addressValidator = $v.tabsAddress.$each[a])"
      >
        <div class="accordion-row_header" header-tag="header" role="tab">
          <b-button block>
            <i class="accordion-row_icon">
              <svg
                width="9"
                height="6"
                viewBox="0 0 9 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.24537e-08 5.4C2.30796e-08 5.736 0.2475 6 0.5625 6L8.4375 6C8.7525 6 9 5.736 9 5.4C9 5.244 8.94375 5.112 8.85375 5.004C8.76375 4.896 4.91625 0.204 4.91625 0.204C4.815 0.0839997 4.66875 -3.7865e-07 4.5 -3.93402e-07C4.33125 -4.08155e-07 4.185 0.0839997 4.08375 0.204L0.14625 5.004C0.0562497 5.112 6.60916e-08 5.244 5.24537e-08 5.4Z"
                  fill="#132D48"
                />
              </svg>
            </i>
            <div>
              <input
                type="radio"
                v-model="selectedAddress"
                v-b-toggle="'adresse-' + address.tabAddressIndex"
                :value="address.tabAddressIndex"
                name=""
                id=""
              />
            </div>
            <span v-if="isAnArtisan">
              {{ "Bénéficiaire" }}
            </span>
            <span v-else>
              {{ "Adresse " + (address.tabAddressIndex + 1) }}
            </span>
            <button
              class="deleteAdresse"
              v-if="!isAnArtisan"
              :disabled="isVIEWER"
              @click="openConfirmationAdresseModal(address.tabAddressIndex)"
              :hidden="simulation.status === 'TO_BE_TRANSFORMED'"
            >
              <svg
                width="15"
                height="17"
                viewBox="0 0 15 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1222 2.83333V1.54948C11.1222 0.703906 10.5043 0 9.65199 0H5.34375C4.49574 0 3.87784 0.703906 3.87784 1.54948V2.83333H0V4.25H0.392045C0.392045 4.25 0.622159 4.27656 0.741477 4.40052C0.860795 4.52448 0.907671 4.79896 0.907671 4.79896L1.71733 15.4992C1.78125 16.8008 1.78125 17 3.25142 17H11.7486C13.2188 17 13.2188 16.8052 13.2827 15.5036L14.0923 4.80781C14.0923 4.80781 14.1392 4.52891 14.2585 4.40495C14.3778 4.28099 14.608 4.25443 14.608 4.25443H15V2.83776H11.1222V2.83333ZM4.77273 1.54948C4.77273 1.12448 5.10511 0.885417 5.52699 0.885417H9.43466C9.85653 0.885417 10.2273 1.12891 10.2273 1.54948V2.83333H4.77273V1.54948ZM4.41051 14.1667L3.97159 5.66667H4.83665L5.28409 14.1667H4.41051ZM7.97301 14.1667H7.03551V5.66667H7.97301V14.1667ZM10.598 14.1667H9.72869L10.1761 5.66667H11.0412L10.598 14.1667Z"
                  fill="#FF6D64"
                />
              </svg>
            </button>
          </b-button>
        </div>
        <b-collapse
          class="accordion-tabpanel"
          :id="'adresse-' + address.tabAddressIndex"
          accordion="acces-row"
          role="tabpanel"
          visible
        >
          <!-- ADDRESS -->
          <div class="address-row">
            <Address
              :addressValidator="addressValidator"
              :isVIEWER="isVIEWER"
              @onZipCodeChange="fetchCityByZipCode"
              @onCityChange="updatePrecarities"
            ></Address>
            <div class="giga-fields">
              <div
                class="beneficiary-group giga-input_field col-lg-8"
                v-if="isAnArtisan"
              >
                <label class="input-field_label beneficiary-label"
                  >{{ $t("beneficiary_type") }}:</label
                >
                <div class="label-group">
                  <label class="radio-style">
                    <input
                      type="radio"
                      v-model="$v.beneficiary.isMoral.$model"
                      :value="false"
                    />
                    Personne physique
                  </label>
                  <label class="radio-style">
                    <input
                      type="radio"
                      v-model="$v.beneficiary.isMoral.$model"
                      :value="true"
                    />
                    Personne morale
                  </label>
                </div>
              </div>
            </div>
            <BeneficiaryPhysical
              v-if="isAnArtisan && !$v.beneficiary.isMoral.$model"
              :isVIEWER="isVIEWER"
              :isEdit="isEdit"
              :beneficiary="$v.beneficiary.physical"
            >
            </BeneficiaryPhysical>
            <BeneficiaryMoral
              v-if="isAnArtisan && $v.beneficiary.isMoral.$model"
              :isVIEWER="isVIEWER"
              :isEdit="isEdit"
              :beneficiary="$v.beneficiary.moral"
            >
            </BeneficiaryMoral>
          </div>

          <div class="giga-fiches">
            <!-- ADD NEW FICHE -->
            <div class="giga-button">
              <button
                id="add-fiche"
                @click.prevent="newTabFiche"
                class="giga-blue_button with-icon"
                :disabled="isVIEWER"
              >
                <i class="giga-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM16.8 13.2H13.2V16.8C13.2 17.46 12.66 18 12 18C11.34 18 10.8 17.46 10.8 16.8V13.2H7.2C6.54 13.2 6 12.66 6 12C6 11.34 6.54 10.8 7.2 10.8H10.8V7.2C10.8 6.54 11.34 6 12 6C12.66 6 13.2 6.54 13.2 7.2V10.8H16.8C17.46 10.8 18 11.34 18 12C18 12.66 17.46 13.2 16.8 13.2Z"
                      fill="white"
                    ></path>
                  </svg>
                </i>
                <span>Ajouter une fiche</span>
              </button>
            </div>

            <!-- FICHE -->
            <b-tabs
              ref="tabsFiche"
              v-tabs-scroll
              nav-class="tabs-scroll"
              v-model="selectedFiche"
              v-if="tabFicheByAddress.length"
            >
              <b-tab
                active
                v-for="(tabFiche, f) in tabFicheByAddress"
                :key="'dyn-tab-' + f + '-' + tabFiche.addressIndex"
                :set="
                  (ficheValidator =
                    $v.tabsFiche.$each[tabsFiche.indexOf(tabFiche)])
                "
              >
                <template #title>
                  <span>{{ "Fiche " + (f + 1) }}</span>
                  <b-button
                    class="close-tab"
                    :disabled="isVIEWER"
                    @click="openConfirmationFicheModal(tabFiche.tabFicheIndex)"
                    :hidden="simulation.status === 'TO_BE_TRANSFORMED'"
                  >
                    <svg
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00073 2.00098L9.60074 9.60098"
                        stroke="#FF6D64"
                        stroke-width="2.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M9.60059 2.00098L2.00058 9.60098"
                        stroke="#FF6D64"
                        stroke-width="2.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </b-button>
                </template>

                <Fiche
                  v-if="tabFicheByAddress.length"
                  :isVIEWER="isVIEWER"
                  :ficheValidator="ficheValidator"
                  :tabsFicheValidation="$v.tabsFiche"
                  :applicationSectors="applicationSectors"
                  :departments="departments"
                  :periods="periods"
                  :addressFicheIndex="tabFiche"
                  :simulationStatus="simulation.status"
                  @setPeriod="setPeriods"
                  @workTypeBySector="workTypeBySector"
                  @fetchFiches="getFiches"
                  @fetchPrecarity="fetchPrecarity"
                  @getFormConditions="getFormConditions"
                  @checkConditions="checkConditions"
                ></Fiche>
              </b-tab>
            </b-tabs>
          </div>
        </b-collapse>
      </div>
    </div>

    <confirm-modal
      :id="modalId"
      :content="$t(modalContent)"
      :loader="confirmLoader"
      fontSize="h3"
      backdrop-close="false"
      @yesClick="modelConfirm()"
      @close="hideConfirmationModal()"
    ></confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { workService } from "../../Works/services";
import moment from "moment";
import eventBus from "@/shared/event.bus";
import { queryConstant } from "@/shared/queryConstant";
import {
  estimationService,
  precarityService,
  simulationService
} from "../services";
import { constants } from "@/shared/constants";
import addressValidation from "../validation/address-validation";
import ficheValidation from "../validation/fiche-validation";
import router from "../../../plugins/vue-router";
import { estimationMapper } from "../services/estimation.mapper";
import Vue from "vue";
import ConfirmModal from "@/components/static/ConfirmModal";
import Address from "@/views/Simulations/Address";
import Fiche from "../Fiche";
import PageLoader from "../../../components/static/pageLoader.vue";
import { helpers } from "@/shared/helper";
import BeneficiaryPhysical from "@/views/Simulations/BeneficiaryPhysical.vue";
import { email, required, requiredIf } from "vuelidate/lib/validators";
import BeneficiaryMoral from "@/views/Simulations/BeneficiaryMoral.vue";

Vue.directive("disable-all-after-carried-out", {
  componentUpdated: (el, binding) => {
    if (binding.value !== "TO_BE_TRANSFORMED") {
      return;
    }
    setTimeout(() => {
      const tags = ["input", "select", "checkbox"];
      tags.forEach(tagName => {
        const nodes = el.getElementsByTagName(tagName);
        for (let i = 0; i < nodes.length; i++) {
          nodes[i].disabled = true;
          nodes[i].classList.add("like-input");
        }
      });
    }, 1);
  }
});

const CONFIRM_SAVE_ESTIMATIONS = "confirm-save-estimations";
const CONFIRM_LOSE_ESTIMATIONS = "confirm-lose-simulation";
const CONFIRM_DELETE_ADRESSE = "confirm-delete-adresse";
const CONFIRM_DELETE_FICHE = "confirm-delete-fiche";

export default {
  components: {
    BeneficiaryMoral,
    BeneficiaryPhysical,
    Address,
    Fiche,
    ConfirmModal,
    PageLoader
  },
  name: "SimulationOperations",
  data() {
    return {
      currentTab: 1,
      edit: null,
      selectedAddress: 0,
      selectedFiche: 0,
      tabsAddress: [],
      beneficiary: {
        isMoral: false,
        physical: {
          id: "",
          email: "",
          lastName: "",
          name: "",
          natureOccupation: "",
          tele1: "",
          tele2: ""
        },
        moral: {
          id: "",
          addressName: "",
          codeCommuneInsee: "",
          codePostal: "",
          libelleAcheminement: "",
          nomCommune: "",
          personneMoralEmail: "",
          personneMoralTelephone: "",
          raisonSociale: "",
          siren: "",
          siret: "",
          type: ""
        }
      },
      tabAddressIndex: 0,
      tabsAddressDelete: null,
      tabsFiche: [],
      tabFicheIndex: 0,
      tabsFicheDelete: null,
      departments: constants.DEPARTEMENTS,
      applicationSectors: constants.APPLICATION_SECTORS,
      workTypes: [],
      selectDocuments: [],
      simulationForm: {
        name: "",
        deliveryDate: ""
      },
      customFilter: queryConstant.setDefaultCustomFilter(),
      queryConfig: queryConstant.DOCUMENT_CONFIG,
      selectedRadio: null,
      optionsRadio: [
        { text: "Oui", value: "true" },
        { text: "Non", value: "false" }
      ],
      modalId: "",
      modalContent: "",
      confirmLoader: false
    };
  },
  validations: {
    tabsAddress: addressValidation,
    tabsFiche: ficheValidation,
    beneficiary: {
      isMoral: { required },
      physical: {
        id: {},
        email: {
          required: requiredIf(function() {
            return this.isAnArtisan && !this.$v.beneficiary.isMoral.$model;
          }),
          email
        },
        lastName: {
          required: requiredIf(function() {
            return this.isAnArtisan && !this.$v.beneficiary.isMoral.$model;
          })
        },
        name: {
          required: requiredIf(function() {
            return this.isAnArtisan && !this.$v.beneficiary.isMoral.$model;
          })
        },
        natureOccupation: {
          required: requiredIf(function() {
            return this.isAnArtisan && !this.$v.beneficiary.isMoral.$model;
          })
        },
        tele1: {
          required: requiredIf(function() {
            return this.isAnArtisan && !this.$v.beneficiary.isMoral.$model;
          }),
          phone: constants.phonePattern
        },
        tele2: {}
      },
      moral: {
        id: {},
        addressName: {
          required: requiredIf(function() {
            return this.isAnArtisan && this.$v.beneficiary.isMoral.$model;
          })
        },
        codeCommuneInsee: {},
        codePostal: {
          required: requiredIf(function() {
            return this.isAnArtisan && this.$v.beneficiary.isMoral.$model;
          })
        },
        libelleAcheminement: {},
        nomCommune: {
          required: requiredIf(function() {
            return this.isAnArtisan && this.$v.beneficiary.isMoral.$model;
          })
        },
        personneMoralEmail: {
          required: requiredIf(function() {
            return this.isAnArtisan && this.$v.beneficiary.isMoral.$model;
          }),
          email
        },
        personneMoralTelephone: {
          required: requiredIf(function() {
            return this.isAnArtisan && this.$v.beneficiary.isMoral.$model;
          }),
          phone: constants.phonePattern
        },
        raisonSociale: {
          required: requiredIf(function() {
            return this.isAnArtisan && this.$v.beneficiary.isMoral.$model;
          })
        },
        siren: {
          required: requiredIf(function() {
            return this.isAnArtisan && this.$v.beneficiary.isMoral.$model;
          }),
          pattern: constants.sirenInvalid
        },
        siret: {
          required: requiredIf(function() {
            return this.isAnArtisan && this.$v.beneficiary.isMoral.$model;
          }),
          pattern: constants.siretInvalid
        },
        type: {
          required: requiredIf(function() {
            return this.isAnArtisan && this.$v.beneficiary.isMoral.$model;
          })
        }
      }
    }
  },
  mounted() {
    this.getPeriodsAction();
    if (this.$route.params["id"]) {
      // case of update
      this.edit = true;
      const id = this.$route.params["id"];
      this.fetchSimulation(id);
      this.mapEstimations(id);
    } else {
      if (this.simulation.id) {
        const id = this.simulation.id;
        this.fetchSimulation(id);
        this.mapEstimations(id);
      } else {
        this.$store.commit("document/setDocuments", []);
        // this.resetSimulationAction();
        this.newTabAdresse();
      }
    }
    this.checkValidationOperations();
  },
  beforeDestroy() {
    const payload = {
      tabsAddress: this.tabsAddress,
      tabsFiche: this.tabsFiche,
      id: this.simulation.id
    };
    if (this.isAnArtisan) {
      if (this.$v.beneficiary.isMoral.$model) {
        const formBeneficiary = this.$v.beneficiary.moral.$model;
        const moralBeneficiary = {
          addressName: formBeneficiary.addressName,
          basePostalCodes: {
            codeCommuneInsee: formBeneficiary.codeCommuneInsee,
            codePostal: formBeneficiary.codePostal,
            libelleAcheminement: formBeneficiary.libelleAcheminement,
            nomCommune: formBeneficiary.nomCommune
          },
          isMoral: this.$v.beneficiary.isMoral.$model,
          personneMoralEmail: formBeneficiary.personneMoralEmail,
          personneMoralTelephone: formBeneficiary.personneMoralTelephone,
          raisonSociale: formBeneficiary.raisonSociale,
          siren: formBeneficiary.siren,
          siret: formBeneficiary.siret,
          type: formBeneficiary.type
        };
        payload.beneficiary = moralBeneficiary;
      } else {
        payload.beneficiary = this.$v.beneficiary.physical.$model;
      }
    }
    eventBus.$emit("save-simulation", payload);
  },
  computed: {
    ...mapGetters("document", ["getDocuments", "getDocumentsTypes"]),
    ...mapGetters("simulation", {
      simulation: "getSimulation",
      isEdit: "isEdit"
    }),
    ...mapGetters("loader", { loading: "getLoading" }),
    ...mapGetters("loader", {
      saveSimulationLoader: "getSaveSimulationLoader"
    }),
    ...mapGetters("period", { periods: "getPeriods" }),
    ...mapGetters("account", { userInfo: "getCurrentUser" }),
    tabFicheByAddress() {
      const tabAddr = this.tabsAddress[this.selectedAddress];
      let ficheByAddr = [];
      if (tabAddr) {
        ficheByAddr = this.tabsFiche.filter(
          value => value.addressIndex === tabAddr.tabAddressIndex
        );
      }
      return ficheByAddr;
    },
    isVIEWER() {
      return helpers.isVIEWER();
    },
    isAnArtisan() {
      return helpers.isAnArtisan();
    },
    operationsStepInvalid() {
      return (
        this.$v.tabsAddress.$invalid ||
        this.$v.tabsFiche.$invalid ||
        this.tabsFiche.length === 0 ||
        (this.isAnArtisan && this.$v.beneficiary.$invalid)
      );
    }
  },
  methods: {
    ...mapActions("document", [
      "getDocumentsAction",
      "getDocumentsTypesAction"
    ]),
    ...mapActions("notification", ["openModal", "closeModal"]),
    ...mapActions("simulation", [
      "persistEstimationsAction",
      "resetSimulationAction"
    ]),
    ...mapActions("period", ["getPeriodsAction"]),
    ...mapMutations("document", ["setSelectedDocuments"]),
    openSimulationInfoModal() {
      this.openModal({ instance: this.$root, id: "simulation-info-modal" });
    },
    ...mapMutations("simulation", ["setSimulationChanged"]),
    persistEstimations() {
      estimationService.persistEstimations(
        this.tabsAddress,
        this.tabsFiche,
        this.simulation.id
      );
    },
    loseSimulation() {
      simulationService.validateOrLose(this.simulation.id, true).then(() => {
        router.router.push("/simulations", Function);
      });
    },
    fetchSimulation(id) {
      workService.fetchWorkById(id).then(
        result => {
          result.deliveryDate = moment(result.deliveryDate, "x").format(
            "YYYY-MM-DD"
          );
          result.desiredReturnDate = moment(
            result.desiredReturnDate,
            "x"
          ).format("YYYY-MM-DD");
          result.submissionTechnicalOfferDate = moment(
            result.submissionTechnicalOfferDate,
            "x"
          ).format("YYYY-MM-DD");
          result.contractingProjectDate = moment(
            result.contractingProjectDate,
            "x"
          ).format("YYYY-MM-DD");
          result.workStartDate = moment(result.workStartDate, "x").format(
            "YYYY-MM-DD"
          );
          //this.$store.commit('simulation/setSimulation', result);
          this.simulationForm = {
            name: result.name,
            deliveryDate: result.deliveryDate,
            desiredReturnDate: result.desiredReturnDate,
            submissionTechnicalOfferDate: result.submissionTechnicalOfferDate,
            contractingProjectDate: result.contractingProjectDate,
            workStartDate: result.workStartDate
          };
        },
        error => {
          console.log(error);
        }
      );
    },
    // selectDocument(id) {
    //   const index = this.selectDocuments.indexOf(id);
    //   if (index > -1) {
    //     this.selectDocuments.splice(index, 1);
    //   } else {
    //     this.selectDocuments.push(id);
    //   }
    //   const docs = [];
    //   const allDocs = JSON.parse(JSON.stringify(this.getDocuments));
    //   this.selectDocuments.forEach((docId) => {
    //     docs.push(find(allDocs, { id: docId }));
    //   });
    //   if (docs && docs.length) {
    //     this.mapDocumentTypes(docs);
    //   }
    // },
    // mapDocumentTypes(docs) {
    //   const documents = docs;
    //   documents.forEach((doc) => {
    //     const types = [];
    //     if (doc.types) {
    //       doc.types.forEach((type) => {
    //         types.push(find(this.getDocumentsTypes, { code: type }));
    //       });
    //     }
    //     doc.types = types;
    //   });
    //   this.setSelectedDocuments(documents);
    // },
    // downloadDocuments() {
    //   documentService.downloadDocuments(this.selectDocuments);
    // },
    // applyFilter(event) {
    //   this.selectDocuments = [];
    //   this.customFilter = event.filter.custom;
    //   this.getDocumentsAction({
    //     id: parseInt(this.$route.params["id"], 10),
    //     customFilter: this.customFilter,
    //   });
    // },
    newTabAdresse() {
      estimationService.newTabAdresse(
        this.tabsAddress,
        this.$v,
        this.tabAddressIndex++
      );
    },
    deleteTabAdresse() {
      this.tabsFiche = this.tabsFiche.filter(
        tabFiche => tabFiche.addressIndex !== this.tabsAddressDelete
      );
      this.tabsAddress = this.tabsAddress.filter(
        addr => addr.tabAddressIndex !== this.tabsAddressDelete
      );
      this.hideConfirmationAdresseModal();
    },
    newTabFiche() {
      estimationService.newTabFiche(
        this.tabsAddress,
        this.tabsFiche,
        this.$v,
        this.selectedAddress,
        this.tabsFiche.length
      );
    },
    deleteTabFiche() {
      this.tabsFiche = this.tabsFiche.filter(
        tabFiche => tabFiche.tabFicheIndex !== this.tabsFicheDelete
      );
      // Réindexer toutes les fiches après suppression
      this.tabsFiche.forEach((tabFiche, index) => {
        tabFiche.tabFicheIndex = index;
      });
      this.hideConfirmationFicheModal();
    },
    modelConfirm() {
      switch (this.modalId) {
        case CONFIRM_SAVE_ESTIMATIONS:
          this.confirmLoader = true;
          this.persistEstimations();
          break;
        case CONFIRM_LOSE_ESTIMATIONS:
          this.loseSimulation();
          break;
        case CONFIRM_DELETE_ADRESSE:
          this.deleteTabAdresse();
          break;
        case CONFIRM_DELETE_FICHE:
          this.deleteTabFiche();
          break;
        default:
          console.log("modelConfirm error");
      }
    },
    hideConfirmationModal() {
      switch (this.modalId) {
        case CONFIRM_DELETE_ADRESSE:
          this.tabsAddressDelete = null;
          break;
        case CONFIRM_DELETE_FICHE:
          this.tabsFicheDelete = null;
          break;
        default:
          console.log("hideConfirmationModal error");
      }
      this.closeModal({ instance: this.$root, id: this.modalId });
    },
    openConfirmationAdresseModal(idItem) {
      this.tabsAddressDelete = idItem;
      this.modalId = CONFIRM_DELETE_ADRESSE;
      this.modalContent = "would-you-like-to-delete-the-address";
      this.doOpenConfirmModal();
    },
    hideConfirmationAdresseModal() {
      this.tabsAddressDelete = null;
      this.hideConfirmationModal();
    },
    openConfirmationFicheModal(idItem) {
      this.tabsFicheDelete = idItem;
      this.modalId = CONFIRM_DELETE_FICHE;
      this.modalContent = "would-you-like-to-delete-the-fiche";
      this.doOpenConfirmModal();
    },
    hideConfirmationFicheModal() {
      this.tabsFicheDelete = null;
      this.hideConfirmationModal();
    },
    doOpenConfirmModal() {
      setTimeout(() => {
        this.openModal({ instance: this.$root, id: this.modalId });
      }, 1);
    },
    fetchCityByZipCode(address) {
      estimationService.fetchCityByZipCode(
        this.tabsFiche,
        this.departments,
        address
      );
    },
    updatePrecarities(address) {
      estimationService.updatePrecarities(this.tabsFiche, address);
    },
    workTypeBySector(tabFiche) {
      tabFiche.workType.$model = "";
      tabFiche.ficheNumber.$model = "";
      tabFiche.fiches.$model = "";
      tabFiche.workTypes.$model = this.applicationSectors.find(
        s => s.value === tabFiche.activityArea.$model
      ).types;
    },
    getFiches(tabFiche) {
      estimationService.fetchFiches(tabFiche);
    },
    fetchPrecarity(tabFiche) {
      const periodId = tabFiche.$model.period.id;
      const departmentCode = tabFiche.$model.departmentCode;
      precarityService.fetchPrecarity(periodId, departmentCode).then(res => {
        tabFiche.precarityControl.id.$model = res.id;
        tabFiche.precarityControl.periodId.$model = tabFiche.$model.period.id;
        tabFiche.precarityControl.department.$model = res.departementCode;
        tabFiche.precarityControl.couplePercentagePrecarityByDepartment.$model =
          res.percentHigh;
        tabFiche.precarityControl.couplePercentageBigPrecarityByDepartment.$model =
          res.percentLow;
      });
    },
    setPeriods(tabFiche) {
      estimationService.setPeriod(tabFiche, this.periods);
    },
    // addressByFiche(tabFiche) {
    //   return this.tabsAddress.filter(
    //     (addr) => addr.tabAddressIndex === tabFiche.addressIndex
    //   );
    // },
    getFormConditions(tabFiche) {
      estimationService.getFormConditions(tabFiche);
      this.fetchPrecarity(tabFiche);
    },
    checkConditions(tabFiche) {
      estimationService.checkConditions(tabFiche);
    },
    mapEstimations(simulationId) {
      simulationService
        .fetchEstimationsBySimulationId(simulationId)
        .then(estimations => {
          if (this.isAnArtisan && estimations?.length > 0) {
            this.$v.beneficiary.isMoral.$model =
              estimations[0].beneficiary.isMoral;
            if (estimations[0].beneficiary?.isMoral) {
              this.beneficiary.moral = estimations[0].beneficiary;
              this.beneficiary.moral.codePostal =
                estimations[0].beneficiary.basePostalCodes.codePostal;
              this.beneficiary.moral.nomCommune =
                estimations[0].beneficiary.basePostalCodes.nomCommune;
            } else {
              this.beneficiary.physical = estimations[0].beneficiary;
            }
          }
          if (estimations.length === 0 && this.userInfo.clientType !== "B2B") {
            this.openSimulationInfoModal();
          }
          if (estimations.length === 0) {
            this.newTabAdresse();
          }
          estimations.forEach(estimation => {
            const address = estimationMapper.mapAddresses(
              estimation,
              this.tabAddressIndex
            );
            this.tabsAddress.push(address);
            estimation.operationDetails.forEach(operationDetail => {
              this.tabsFiche.push(
                estimationMapper.mapFiches(
                  operationDetail,
                  address,
                  this.periods,
                  this.tabAddressIndex,
                  this.tabFicheIndex++
                )
              );
            });
            this.checkValidationOperations();
            this.tabAddressIndex++;
          });
        });
    },
    checkValidationOperations() {
      const invalid = this.operationsStepInvalid;
      eventBus.$emit("invalid-form", invalid);
    }
  },
  watch: {
    "$v.$invalid": function() {
      const invalid = this.operationsStepInvalid;
      eventBus.$emit("invalid-form", invalid);
    },
    tabsAddress: {
      handler() {
        this.setSimulationChanged(true);
      },
      deep: true
    },
    tabsFiche: {
      handler() {
        this.setSimulationChanged(true);
      },
      deep: true
    }
  }
};
</script>
<style>
.beneficiary-group {
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
}
.radio-style {
  cursor: pointer;
  margin: 10px 40px 10px 0px;
}
.beneficiary-label {
  font-weight: bold !important;
  font-size: 15px !important;
  margin: 10px 40px 10px 0px;
}
.label-group {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-left: 10px;
}
.label-group input[type="radio"] {
  margin-right: 5px;
}
.label-group label {
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #002855;
}
.label-group label:last-child {
  margin-right: 0;
}
</style>
