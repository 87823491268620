<template>
  <div id="SimulationImportMass">
    <template>
      <div class="simulator-step_header" v-if="!isEdit">
        <h3 class="simulator-step_title">{{ $t("add_the_new_simulation") }}</h3>
        <p class="simulator-step_description">
          Importer des opérations en masse:
        </p>
      </div>

      <div class="giga-fields fields-style">
        <div class="col-lg-6">
          <div class="giga-input_field ">
            <label class="input-field_label">{{ $t("operation_type") }}</label>
            <div class="input-field_area">
              <select
                id="operation-type"
                @change="fetchFiches"
                :class="
                  $v.importOperations.isMorale.$anyDirty &&
                  $v.importOperations.isMorale.$invalid
                    ? 'field-hasError'
                    : ''
                "
                v-model="$v.importOperations.isMorale.$model"
                class="small-input"
                :disabled="isVIEWER"
              >
                <option key="MORALE" :value="true">Personne morale</option>
                <option key="PHYSIQUE" :value="false">Personne physique</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="giga-input_field ">
            <label class="input-field_label">{{ $t("fiche") }}</label>
            <div class="input-field_area">
              <select
                id="select-fiches"
                @change="fetchMappings(true)"
                :class="
                  $v.importOperations.fiche.$anyDirty &&
                  $v.importOperations.fiche.$invalid
                    ? 'field-hasError'
                    : ''
                "
                v-model="$v.importOperations.fiche.$model"
                class="small-input"
                :disabled="isVIEWER"
              >
                <option
                  v-for="fiche in fiches"
                  :key="fiche.id"
                  :value="fiche.id"
                  >{{ fiche.number }}</option
                >
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="giga-input_field ">
            <label class="input-field_label">{{ $t("mapping") }}</label>
            <div class="input-field_area">
              <select
                id="select-mappings"
                :class="
                  $v.importOperations.mapping.$anyDirty &&
                  $v.importOperations.mapping.$invalid
                    ? 'field-hasError'
                    : ''
                "
                v-model="$v.importOperations.mapping.$model"
                class="small-input"
                :disabled="isVIEWER"
              >
                <option
                  v-for="mapping in mappings"
                  :key="mapping.id"
                  :value="mapping.id"
                  >{{ mapping.name }}</option
                >
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="consultant-template"
            v-if="$v.importOperations.mapping.$model"
          >
            <img src="@/assets/images/paper-line.svg" />
            <span>Télécharger le template consultant</span>
            <button @click="downloadMappingFile()">
              <span class="material-symbols-outlined">download</span>
            </button>
          </div>
        </div>
        <import-mass-documents
          class="import-doc-input"
          :simulation="true"
          :files="files"
          :importInMassOperations="previewUpload"
          :isSimulationStepper="true"
          @estimations-preview-updated="handleEstimationsPreviewUpdate"
        ></import-mass-documents>
        <div
          class="operations-list-div"
          :hidden="
            !operations ||
              (operations && operations.length === 0) ||
              simulation.status === 'NEW'
          "
        >
          <list-operations-in-mass
            @operations-updated="handleOperationsUpdate"
          ></list-operations-in-mass>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import eventBus from "@/shared/event.bus";
import PageLoader from "../../../components/static/pageLoader.vue";
import { constants } from "@/shared/constants";
import { helpers } from "@/shared/helper";
import { ficheService } from "@/views/Simulations/services/fiche.service";
import { importService } from "@/views/Simulations/services/import.service";
import ImportMassDocuments from "@/views/Simulations/import-mass-estimations/ImportMassDocuments.vue";
import Vue from "vue";
import ListOperationsInMass from "@/views/Simulations/import-mass-estimations/ListOperationsInMass.vue";

export default {
  name: "SimulationImportMass",
  components: { ListOperationsInMass, ImportMassDocuments },
  validations: {
    importOperations: {
      isMorale: { required },
      fiche: { required },
      mapping: { required }
    }
  },
  mounted() {},
  beforeDestroy() {
    if (this.estimationsPreview) {
      eventBus.$emit("save-simulation", {
        previewUpload: this.previewUpload,
        file: this.importInMassDocuments[0]?.file
      });
    }
    this.estimationsPreview = null;
  },
  computed: {
    ...mapGetters("simulation", {
      simulation: "getSimulation",
      isEdit: "isEdit",
      simulationType: "getSimulationType",
      users: "getUsers"
    }),
    ...mapGetters("loader", { loading: "getLoading" }),
    ...mapGetters("account", { userInfo: "getCurrentUser" }),
    ...mapGetters("document", {
      importInMassDocuments: "getDraftImportInMassDocuments"
    }),
    isVIEWER() {
      return helpers.isVIEWER();
    }
  },
  data() {
    return {
      USER_TYPE: constants.USER_TYPE,
      ROLE_TYPE: constants.ROLE_TYPE,
      IMPORT_TYPE: constants.IMPORT_TYPE,
      importOperations: {
        isMorale: null,
        fiche: null,
        mapping: null
      },
      fiches: [],
      mappings: [],
      previewUpload: {},
      estimationsPreview: null,
      operations: null,
      importDetail: null,
      files: null
    };
  },
  methods: {
    ...mapMutations("simulation", [
      "setSimulation",
      "setUsersAgence",
      "setSimulationChanged"
    ]),
    ...mapActions("simulation", ["fetchUsersByAgency"]),
    ...mapMutations("document", ["setDraftImportInMassDocuments"]),
    fetchFiches() {
      ficheService
        .getFichesWithMapping(
          !this.importOperations.isMorale,
          this.importOperations.isMorale
        )
        .then(fiches => {
          this.fiches = fiches;
        });
    },
    fetchMappings(clearMappins = false) {
      const ficheId = this.importOperations.fiche;
      importService
        .getMappingsNamesByFiche(
          !this.importOperations.isMorale,
          this.importOperations.isMorale,
          ficheId
        )
        .then(mappings => {
          if (clearMappins) {
            this.$v.importOperations.mapping.$model = null;
          }
          this.mappings = mappings;
        });
    },
    downloadMappingFile() {
      const mapping = this.mappings.find(
        mapping => mapping.id === this.$v.importOperations.mapping.$model
      );
      importService
        .getDownloadMappingFile(mapping.id, mapping.name)
        .then(file => {
          helpers.downloadFile(file);
          Vue.prototype.$notification.success(
            "Le template a été téléchargé avec succès."
          );
        })
        .catch(() => {
          Vue.prototype.$notification.error(
            "Erreur lors de téléchargement du template !"
          );
        });
    },
    preparePreviewUpload() {
      const fiche = this.fiches.find(
        fiche => fiche.id === this.$v.importOperations.fiche.$model
      );
      const upload = {
        mappingId: this.$v.importOperations.mapping.$model,
        ficheName: fiche.number,
        clientRefId: this.simulation.id,
        lotId: null,
        fileName: ""
      };
      const massImport = {
        upload: upload,
        forceImport: true,
        importType: this.isEdit
          ? this.IMPORT_TYPE.EDIT_ESTIMATION_IMPORT
          : this.IMPORT_TYPE.ESTIMATION_IMPORT,
        opportunityId: null
      };
      this.previewUpload = massImport;
    },
    handleEstimationsPreviewUpdate(newPreview) {
      this.estimationsPreview = newPreview;
    },
    handleOperationsUpdate(newPreview) {
      this.operations = newPreview.content;
    },
    fillInputs() {
      if (this.isEdit && this.operations) {
        importService
          .getImportInMassDetailBySimulationId(this.simulation.id)
          .then(importDetail => {
            this.importDetail = importDetail;
            this.importOperations.isMorale = importDetail.moral;
            this.importOperations.fiche = importDetail.ficheId;
            this.importOperations.mapping = importDetail.mappingId;
            this.fetchFiches();
            this.fetchMappings();
            this.$v.importOperations.isMorale.$model = this.importOperations.isMorale;
            this.$v.importOperations.fiche.$model = this.importOperations.fiche;
            this.$v.importOperations.mapping.$model = this.importOperations.mapping;
            const document = {
              ...this.importDetail.document,
              originalFileName: this.importDetail.document.name,
              fileName: this.importDetail.document.name
            };
            delete document.name;
            this.files = [document];
            this.setDraftImportInMassDocuments(this.files);
          });
      }
    }
  },
  watch: {
    "$v.$invalid": function() {
      const invalid = this.$v.$invalid;
      eventBus.$emit("invalid-form", invalid);
      if (!invalid) {
        if (this.isEdit) {
          setTimeout(() => {
            this.preparePreviewUpload();
          }, 800);
        } else {
          this.preparePreviewUpload();
        }
      }
    },
    simulation: {
      handler() {
        this.setSimulationChanged(true);
      },
      deep: true
    },
    operations(newValue) {
      if (newValue) {
        this.fillInputs();
      }
    }
  }
};
</script>
<style lang="scss">
.field-hasError {
  box-shadow: 0px 0px 3px 0px #e90e00 !important;
}
.fields-style {
  width: 100% !important;
}
.giga-fields .giga-input_field:last-child {
  margin-bottom: 20px;
}
.simulator-step_header {
  margin-bottom: 30px !important;
}
.consultant-template {
  width: 100%;
  height: 46px;
  flex-grow: 1;
  margin-top: 33px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 18px 16px;
  border-radius: 6px;
  background-color: #f4f6fa;
  font-size: 15px;
  font-weight: 600;
  color: #132d48;

  .article-icon {
    margin-right: 9px;
  }
  button {
    margin-left: auto;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    outline: none;
  }
}
.import-doc-input {
  width: 100%;
  margin: auto 15px;
}
.operations-list-div {
  width: 100%;
  flex-grow: 0;
  margin: 12px auto 0;
  padding: 35px 41px 45px 47px;
  border-radius: 6px;
  box-shadow: 0 0 25px 0 rgba(52, 74, 87, 0.05);
  border: solid 2px #e0e7ee;
  background-color: #fff;
}
</style>
