<template>
  <div id="Simulator">
    <div>
      <ul class="gigwhat-navTabs">
        <template v-for="(step, index) in simulationSteps">
          <li
            class="navTab_item"
            :class="[
              currentStep === index ? 'active-navTab' : '',
              currentStep > index ? 'done-stepTab' : ''
            ]"
            :key="index"
          >
            <button :id="'step-' + step.key">
              <span v-if="currentStep > index" class="navTab-item_done">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.782"
                  height="7.769"
                  viewBox="0 0 10.782 7.769"
                >
                  <path
                    id="Tracé_2381"
                    data-name="Tracé 2381"
                    d="M67.246,104.023a1.113,1.113,0,0,0,.79-.327l5.534-5.534a1.117,1.117,0,0,0-1.58-1.58l-4.744,4.744L65.023,99.1a1.117,1.117,0,1,0-1.58,1.58l3.013,3.013a1.114,1.114,0,0,0,.79.327Z"
                    transform="translate(-63.115 -96.254)"
                    fill="#808fa3"
                  />
                </svg>
              </span>
              <span v-else class="navTab-item_number">{{ index + 1 }}</span>
              <span class="navTab-item_label">{{ $t(step.name) }}</span>
              <b-tooltip
                :target="'step-' + step.key"
                placement="bottom"
                triggers="focus"
              >
                {{ step.name }}
              </b-tooltip>
            </button>
          </li>
        </template>
      </ul>
      <simulation-wizzard
        :currentStep="currentStep"
        :selectedSteps="simulationStepsKeys"
      ></simulation-wizzard>

      <div
        v-if="showNav"
        class="
          giga-simulator_footer
          giga-document_footer
          giga-flex giga-flex_end
        "
      >
        <div class="giga-back-page" v-click-outside>
          <b-link
            :to="{ name: 'Simulations' }"
            class="nav-link simulations-link"
          >
            <img
              class="close-icon"
              src="@/assets/images/mingcute-back-line.svg"
              alt=""
            />
            <span class="giga-back-page-text">
              Retourner à la page des simulations
            </span>
          </b-link>
        </div>
        <div
          v-if="currentStep > 0 && currentStep !== steps.length"
          class="giga-button"
        >
          <button
            id="btn-precedent"
            @click="goPrev"
            class="giga-blue_outlined_button no-icon"
          >
            <span>Précédent</span>
          </button>
        </div>
        <div class="giga-button">
          <button
            id="btn-next"
            @click="goNext"
            :disabled="isInvalid"
            class="giga-green_button no-icon"
          >
            <span v-if="isNextFinish"> Terminer </span>
            <span v-else>Suivant</span>
          </button>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-confirm_leave"
      title="BootstrapVue"
      modal-class="giga-modal giga-actions_modal"
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <h2 class="giga-modal_title">{{ $t("save_changes") }}</h2>
      <div class="giga-modal_body">
        <p>{{ $t("confirm_save") }}</p>
      </div>
      <div class="giga-modal_footer">
        <div class="giga-button">
          <button
            id="btn-leave"
            class="giga-blue_outlined_button no-icon"
            @click="ignoreChanges"
          >
            {{ $t("leave") }}
          </button>
        </div>
        <div class="giga-button">
          <button
            id="btn-save"
            class="giga-green_button no-icon"
            @click="applyChanges"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SimulationWizzard from "./SimulationWizzard.vue";
import { workService } from "../Works/services";
import { required, requiredIf } from "vuelidate/lib/validators";
import moment from "moment";
import { map } from "lodash";
import eventBus from "@/shared/event.bus";
import { estimationService, simulationService } from "./services";
import store from "@/store";
import { simulation } from "@/store/simulation.module";
import Default from "@/layouts/wrappers/default.vue";
import { constants } from "@/shared/constants";
import { importService } from "@/views/Simulations/services/import.service";

export default {
  name: "Simulator",
  components: { SimulationWizzard },
  data() {
    return {
      documents: 2,
      infoModal: false,
      simulationForm: {
        name: "",
        deliveryDate: "",
        simulationType: "SIMPLE",
        desiredReturnDate: "",
        submissionTechnicalOfferDate: "",
        contractingProjectDate: "",
        workStartDate: "",
        siret: "",
        comment: "",
        agence: null,
        users: null
      },
      importOperations: {
        isMorale: false,
        fiche: {},
        mapping: {}
      },
      previousComponentName: null,
      simpleSimulation: [],
      complexSimulation: [],
      invalidForm: true,
      triggerSave: false,
      simulationSteps: [],
      simulationStepsKeys: [],
      USER_TYPE: constants.USER_TYPE,
      ROLE_TYPE: constants.ROLE_TYPE,
      SIMULATION_TYPE: constants.SIMULATION_TYPE
    };
  },
  validations: {
    simulationForm: {
      name: {
        required
      },
      deliveryDate: {
        required
      },
      simulationType: {
        required
      },
      desiredReturnDate: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        })
      },
      submissionTechnicalOfferDate: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        })
      },
      contractingProjectDate: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        })
      },
      workStartDate: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        })
      },
      siret: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        }),
        customValidation: function(value) {
          return this.siretIsValid;
        }
      },
      agence: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        })
      },
      users: {
        required
      },
      comment: {
        required
      }
    }
  },
  created() {
    if (this.$route.params["id"]) {
      this.goTo(0);
    }
  },
  mounted() {
    if (this.$route.params["id"]) {
      // case of update
      this.edit = true;
      const id = this.$route.params["id"];
      this.setSimulation({});
      this.fetchSimulation(id);
      switch (this.simulationType) {
        case this.SIMULATION_TYPE.COMPLEX:
          this.editComplexSimulation();
          break;
        case this.SIMULATION_TYPE.SIMPLE:
          this.editSimpleSimulation();
          break;
        case this.SIMULATION_TYPE.IMPORT_MASS:
          this.editImportMassSimulation();
          break;
      }
    } else {
      // case of add
      this.$store.commit("document/setDocuments", []);
      this.resetSimulationAction();
      switch (this.simulationType) {
        case this.SIMULATION_TYPE.COMPLEX:
          this.getComplexSimulation();
          break;
        case this.SIMULATION_TYPE.SIMPLE:
          this.getSimpleSimulation();
          break;
        case this.SIMULATION_TYPE.IMPORT_MASS:
          this.getImportMassSimulation();
          break;
      }
    }
    this.simulationStepsKeys = this.simulationSteps.map(s => s.key);
    eventBus.$on("invalid-form", invalid => {
      this.invalidForm = invalid;
    });
    eventBus.$on("save-simulation", payload => {
      if (this.triggerSave) {
        this.save(this.previousComponentName, payload);
      }
    });
  },
  computed: {
    ...mapGetters("loader", { loading: "getLoading" }),
    ...mapGetters("simulation", {
      currentStep: "getCurrentStep",
      simulationType: "getSimulationType",
      simulation: "getSimulation",
      isCurrentStep: "isCurrentStep",
      steps: "getSteps",
      componentName: "getComponentName",
      simulationChanged: "getSimulationChanged"
    }),
    ...mapGetters("simulation", ["getGoNextStepDisabled"]),
    ...mapGetters("document", [
      "getDocuments",
      "getDraftDocuments",
      "getLeavePage",
      "getDocFormInvalid"
    ]),
    ...mapGetters("account", { userInfo: "getCurrentUser" }),
    isEdit() {
      const isEdit = this.$route.path.includes("/edit");
      this.setIsEdit(isEdit);
      return isEdit;
    },
    isNextFinish() {
      return this.currentStep === this.simulationSteps.length - 2;
    },
    showNav() {
      return this.componentName !== "FinalStep";
    },
    showNextTerminateButton() {
      return true;
    },
    isInvalid() {
      return (
        (this.invalidForm && (this.isEdit || this.currentStep > 0)) ||
        (this.getDocFormInvalid && this.componentName === "SimulationDocuments")
      );
    },
    fieldsRequiredB2B2B() {
      return this.userInfo.clientType === this.USER_TYPE.B2B2B;
    },
    simulationTypeSimpleOrPhoenix() {
      return ["SIMPLE", "PHOENIX"].includes(this.simulationType);
    }
  },
  methods: {
    ...mapMutations("simulation", {
      goTo: "goToStep",
      changeStatus: "changeStepStatus",
      setIsEdit: "setIsEdit",
      setSimulation: "setSimulation",
      setSimulationChanged: "setSimulationChanged"
    }),
    ...mapMutations("document", ["setDraftDocuments"]),
    ...mapActions("simulation", [
      "persistSimulationAction",
      "resetSimulationAction"
    ]),
    ...mapActions("document", [
      "saveDocumentsAction",
      "editDocumentTypesAction"
    ]),
    goNext() {
      this.previousComponentName = this.componentName;
      this.triggerSave = true;
      this.goTo(this.currentStep + 1);
      this.setSimulationChanged(false);
      this.changeStatus({ key: this.currentStep, status: "CURRENT" });
      this.changeStatus({ key: this.currentStep - 1, status: "DONE" });
    },
    goPrev() {
      this.goTo(this.currentStep - 1);
      if (!this.isEdit && this.currentStep === 0) {
        switch (this.simulationType) {
          case this.SIMULATION_TYPE.COMPLEX:
            this.getComplexSimulation();
            break;
          case this.SIMULATION_TYPE.SIMPLE:
            this.getSimpleSimulation();
            break;
          case this.SIMULATION_TYPE.IMPORT_MASS:
            this.getImportMassSimulation();
            break;
        }
        return;
      }
      const id = this.$route.params["id"];
      if (id) {
        // case of update
        this.edit = true;
        this.setSimulation({});
        this.fetchSimulation(id);
      } else {
        // case of add
        const id = this.simulation.id;
        this.fetchSimulation(id);
      }
    },
    // simulation simple
    getSimpleSimulation() {
      this.simulationSteps =
        this.userInfo.clientType === this.USER_TYPE.B2B2B
          ? this.steps.filter(item => ![3, 4].includes(item.key))
          : this.steps.filter(item => ![2, 3, 4].includes(item.key));
    },

    editSimpleSimulation() {
      this.simulationSteps =
        this.userInfo.clientType === this.USER_TYPE.B2B2B
          ? this.steps.filter(item => ![0, 3, 4].includes(item.key))
          : this.steps.filter(item => ![0, 2, 3, 4].includes(item.key));
    },

    // simulation complex
    getComplexSimulation() {
      this.simulationSteps =
        this.userInfo.clientType === this.USER_TYPE.B2B2B
          ? this.steps.filter(item => item.key !== 4)
          : this.steps.filter(item => ![2, 4].includes(item.key));
    },

    editComplexSimulation() {
      this.simulationSteps =
        this.userInfo.clientType === this.USER_TYPE.B2B2B
          ? this.steps.filter(item => ![0, 4].includes(item.key))
          : this.steps.filter(item => ![0, 2, 4].includes(item.key));
    },

    // simulation Import en masse
    getImportMassSimulation() {
      this.simulationSteps =
        this.userInfo.clientType === this.USER_TYPE.B2B2B
          ? this.steps.filter(item => ![3, 4].includes(item.key))
          : this.steps.filter(item => ![2, 3].includes(item.key));
    },

    editImportMassSimulation() {
      this.simulationSteps =
        this.userInfo.clientType === this.USER_TYPE.B2B2B
          ? this.steps.filter(item => ![0, 3, 4].includes(item.key))
          : this.steps.filter(item => ![0, 2, 3].includes(item.key));
    },

    fetchSimulation(id) {
      workService.fetchWorkById(id).then(
        result => {
          result.deliveryDate =
            result.deliveryDate > 0
              ? moment(result.deliveryDate, "x").format("YYYY-MM-DD")
              : null;
          result.desiredReturnDate =
            result.desiredReturnDate > 0
              ? moment(result.desiredReturnDate, "x").format("YYYY-MM-DD")
              : null;
          result.submissionTechnicalOfferDate =
            result.submissionTechnicalOfferDate > 0
              ? moment(result.submissionTechnicalOfferDate, "x").format(
                  "YYYY-MM-DD"
                )
              : null;
          result.contractingProjectDate =
            result.contractingProjectDate > 0
              ? moment(result.contractingProjectDate, "x").format("YYYY-MM-DD")
              : null;
          result.workStartDate =
            result.workStartDate > 0
              ? moment(result.workStartDate, "x").format("YYYY-MM-DD")
              : null;
          result.agence = result.users?.[0]?.agence;
          if (
            this.userInfo.clientType === this.USER_TYPE.B2B2B &&
            result.agence
          ) {
            this.$store.dispatch(
              "simulation/fetchUsersByAgency",
              result.agence
            );
          }
          this.$store.commit("simulation/setSimulation", result);
          if (this.isEdit) {
            this.$store.commit(
              "simulation/setSimulationType",
              result.simulationType
            );
          }
          this.simulationForm = {
            name: result.name,
            deliveryDate: moment(result.deliveryDate, "x").format("YYYY-MM-DD"),
            simulationType: result.simulationType,
            desiredReturnDate: moment(result.desiredReturnDate, "x").format(
              "YYYY-MM-DD"
            ),
            submissionTechnicalOfferDate: moment(
              result.submissionTechnicalOfferDate,
              "x"
            ).format("YYYY-MM-DD"),
            contractingProjectDate: moment(
              result.contractingProjectDate,
              "x"
            ).format("YYYY-MM-DD"),
            workStartDate: moment(result.workStartDate, "x").format(
              "YYYY-MM-DD"
            ),
            siret: result.siret,
            comment: result.comment,
            agence: result.users?.[0]?.agence,
            users: result.users
          };
        },
        error => {
          console.log(error);
        }
      );
    },
    save(componentName, payload) {
      if (componentName === "SimulationDocuments" && this.simulation.isDraft) {
        this.convertDraftToSimulation();
      }
      switch (componentName) {
        case "SimulationInfos":
          this.persistSimulation(payload);
          break;
        case "SimulationPlanning":
          this.persistDatesSimulation(payload);
          break;
        case "SimulationImportMass":
          this.persistEstimationsInMass(payload);
          break;
        case "SimulationOperations":
          this.persistEstimations(payload);
          break;
        case "SimulationDocuments":
          this.saveDraftDocuments();
          this.editDocumentType();
          break;
      }
      this.triggerSave = false;
    },
    persistSimulation(simulation) {
      if (!simulation.type) {
        simulation.type = this.simulationType;
      }
      this.persistSimulationAction({ simulation: simulation });
    },
    saveDraftDocuments() {
      const drafts = this.getDraftDocuments;
      if (drafts && drafts.length > 0) {
        drafts.forEach(document => {
          document.types = map(document.types, "code");
        });
        this.setDraftDocuments(null);
        this.saveDocumentsAction({
          id: parseInt(this.$route.params["id"], 10),
          customFilter: this.customFilter,
          documentsToUpload: drafts
        });
      }
    },
    editDocumentType() {
      const editedDocs = [];
      this.getDocuments.forEach(document => {
        const types = document.types;
        editedDocs.push({ id: document.id, types: map(types, "code") });
      });
      this.editDocumentTypesAction({ documents: editedDocs });
    },
    persistEstimations(payload) {
      estimationService.persistEstimations(
        payload.tabsAddress,
        payload.tabsFiche,
        payload.beneficiary,
        payload.id
      );
    },
    persistDatesSimulation(payload) {
      simulationService.persistDatesSimulation(payload);
    },
    persistEstimationsInMass(payload) {
      importService.persistImport(payload?.previewUpload, payload?.file);
    },
    saveComment(payload) {
      simulationService.saveComment(payload.id, payload.comment);
    },
    convertDraftToSimulation() {
      this.simulation.isDraft = false;
      this.persistSimulationAction({ simulation: this.simulation });
    },
    ignoreChanges() {
      this.$bvModal.hide("modal-confirm_leave");
    },
    applyChanges() {
      this.goNext();
      this.$bvModal.hide("modal-confirm_leave");
    }
  },
  watch: {
    simulationType() {
      const id = this.$route.params["id"];
      if (id) {
        // Edit case
        switch (this.simulationType) {
          case this.SIMULATION_TYPE.COMPLEX:
            this.editComplexSimulation();
            break;
          case this.SIMULATION_TYPE.SIMPLE:
            this.editSimpleSimulation();
            break;
          case this.SIMULATION_TYPE.IMPORT_MASS:
            this.editImportMassSimulation();
            break;
        }
      } else {
        //Add case
        switch (this.simulationType) {
          case this.SIMULATION_TYPE.COMPLEX:
            this.getComplexSimulation();
            break;
          case this.SIMULATION_TYPE.SIMPLE:
            this.getSimpleSimulation();
            break;
          case this.SIMULATION_TYPE.IMPORT_MASS:
            this.getImportMassSimulation();
            break;
        }
      }
    },
    simulationSteps: {
      handler(newVal) {
        this.simulationStepsKeys = this.simulationSteps.map(s => s.key);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.simulationChanged) {
      this.$root.$emit("bv::show::modal", "modal-confirm_leave");
      this.$root.$on("bv::modal::hidden", () => {
        next();
      });
    } else {
      next();
    }
  },
  beforeDestroy() {
    eventBus.$off("invalid-form");
    eventBus.$off("save-simulation");
  }
};
</script>
<style lang="scss">
.giga-back-page {
  margin-right: auto;
}
.giga-back-page-text {
  width: 259px;
  height: 18px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #132d48;
}
</style>
